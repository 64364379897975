import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import { Grid, IconButton, Typography, Snackbar, Alert } from '@mui/material';

export default function PostReplenishment(props) {
  const [open, setOpen] = React.useState(false);
  const [openAllert, setOpenAllert] = React.useState(false)
  const [balance, setBalance] = React.useState(0);
  const [modalTitle, setModalTitle] = React.useState('');

  const {info} = props;

//   const changeBalance = () =>{
//     if (balance > 0) {
//       fetch (`https://atod.online/cards.php`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem('jwt')}`,
//         },
//         body: JSON.stringify({wsh:+props.wash, updb:balance, cud:info.cardUid, event:25})
//       })
//       .then(response => {
//         if (response.ok) {
//             setModalTitle('Баланс успешно пополнен');
//             setCurrentBalance(+currentBalance + +balance);
//             setTimeout(handleClose, 3000);
//         } else if (response.status === 401) {
//             localStorage.removeItem("jwt");
//             throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
//         } else {
//             console.log(response)
//             throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
//         }
//       })
//       .catch(error => setModalTitle(`${error.name}: ${error.message}`))
//     }
//   }

  const handleClickOpen = () => {
    if (props.isActive !== '1') {
      setOpen(true);
    } else setOpenAllert(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAllert = () => {
    setOpenAllert(false)
  }

  return (
    <>
    <div>
      
    <Button
    variant="contained" 
    color="secondary"
    sx={{ width:'150px'}}
    onClick={handleClickOpen}
    // disabled = {dayjs().isAfter(dayjs(lastUpdateTime).add(120, 'second')) ? true : false}
    >
        <Typography textAlign={'end'} gutterBottom color={'text.secondary'} textTransform={'uppercase'} fontSize={12}>Пополнить</Typography>
    </Button>

      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Пополнить терминал</DialogTitle>
        <DialogContent >
          <DialogContentText>
            {modalTitle}
          </DialogContentText>
          <Grid container spacing={2} justifyContent={'space-around'}>
          <Grid item >
            <TextField
              autoFocus
              margin="dense"
              id="balance"
              label="Сумма пополнения"
              value={balance}
              fullWidth
              type="number"
              variant="outlined"
              onChange={(e) => setBalance(e.target.value)}
            />
          </Grid>  
          <Grid  item alignSelf={'end'} marginBottom={'4px'}>
            <Button variant='outlined' onClick={() => setBalance(+balance + 50)}>+50</Button>
          </Grid>  
          <Grid  item alignSelf={'end'} marginBottom={'4px'}>
            <Button variant='outlined' onClick={() => setBalance(+balance + 100)}>+100</Button>
          </Grid> 
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button>Пополнить</Button>
        </DialogActions>
      </Dialog>
    </div>
    
    </>
  );
}