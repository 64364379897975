import {
  Card,
  CardContent,
  Grid,
  Icon,
  SvgIcon,
  IconButton,
  Typography,
  Popover,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  useTheme,
} from "@mui/material";

import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import NetworkWifi2BarIcon from "@mui/icons-material/NetworkWifi2Bar";
import NetworkWifi1BarIcon from "@mui/icons-material/NetworkWifi1Bar";
import SignalWifi0BarIcon from "@mui/icons-material/SignalWifi0Bar";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditPost from "./EditPost";
import SavingsIcon from "@mui/icons-material/Savings";
import { useState } from "react";
import {
  BiNoSignal,
  BiSignal1,
  BiSignal2,
  BiSignal3,
  BiSignal4,
  BiSignal5,
} from "react-icons/bi";
import { ReactComponent as Wifi0 } from "../../src/icons/wifi/Wi-fi_icon-06.svg";
import { ReactComponent as Wifi1 } from "../../src/icons/wifi/Wi-fi_icon-05.svg";
import { ReactComponent as Wifi2 } from "../../src/icons/wifi/Wi-fi_icon-04.svg";
import { ReactComponent as Wifi3 } from "../../src/icons/wifi/Wi-fi_icon-03.svg";
import { ReactComponent as Wifi4 } from "../../src/icons/wifi/Wi-fi_icon-02.svg";
import { ReactComponent as Wifi5 } from "../../src/icons/wifi/Wi-fi_icon-01.svg";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import PostReplenishment from "./PostReplenichment";

// Подключение плагинов
dayjs.extend(utc);
dayjs.extend(timezone);


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PostInfo = (props) => {
  const [visiblePostName, setVisiblePostName] = useState("all");
  const { postItems } = props;
  const [anchorElArr, setAnchorElArr] = useState([]);
  const [incasationsData, setIncasationsData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("Произвести инкассацию?");
  const [isAdded, setIsAdded] = useState(false);

  const theme = useTheme();

  const handleClick = (event, index) => {
    const newAnchorElArr = [...anchorElArr];
    newAnchorElArr[index] = event.currentTarget;
    setAnchorElArr(newAnchorElArr);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setTimeout(setIsAdded, 1000, false);
    setTimeout(setModalTitle, 1000, "Произвести инкассацию?");
  };

  const handleClose = (index) => {
    const newAnchorElArr = [...anchorElArr];
    newAnchorElArr[index] = null;
    setAnchorElArr(newAnchorElArr);
  };

  const open =
    anchorElArr.length > 0
      ? anchorElArr.map((item, index) => Boolean(item))
      : [false];
  const id = open ? "simple-popover" : undefined;

  const displayWifiIcon = (wifi) => {
    if (+wifi > -30) return Wifi5;
    else if (+wifi <= -30 && +wifi > -55) return Wifi4;
    else if (+wifi <= -55 && +wifi > -67) return Wifi3;
    else if (+wifi <= -67 && +wifi > -70) return Wifi2;
    else if (+wifi <= -70 && +wifi > -80) return Wifi1;
    else return Wifi0;
  };

  const checkProgramm = (programmNum) => {
    switch (+programmNum) {
      case 1:
        return "вода";
      case 2:
        return "пена";
      case 3:
        return "воск";
      case 4:
        return "осмос";
      case 5:
        return "пылесос";
      case 6:
        return "антимошка";
      case 7:
        return "горячая вода";
      case 8:
        return "тефлон";
      case 9:
      case 10:
      case 13:
      case 14:
      case 15:
      case 16:
        return "стоп";
      case 0:
      case 12:
      case 17:
      case 18:
        return "Свободен";
      case 27:
      case 28:
      case 29:
      case 30:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        return "Сервис";
      default:
        return "Ошибка";
    }
  };

  const makeIncassation = (moneyObj) => {
    const sample = {
      cash: 0,
      coins: 0,
      terminal: 0,
    };

    setIncasationsData(() => ({ ...sample, ...moneyObj }));
    if (!moneyObj.cash && !moneyObj.coins && !moneyObj.terminal) {
      return;
    } else setModalOpen(true);
  };

  const sendData = () => {
    fetch("https://atod.online/collection.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      body: JSON.stringify(incasationsData),
    })
      .then((response) => {
        if (response.ok) {
          console.log(response);
          setIsAdded(true);
          setModalTitle("Инкассация успешно произведена!");
          setTimeout(handleCloseModal, 3000);
        } else if (response.status === 401) {
          localStorage.removeItem("jwt");
          console.log(response);
          throw new Error(`Ошибка ${response.status} : ${response.statusText}`);
        } else {
          console.log(response);
          throw new Error(`Ошибка ${response.status} : ${response.statusText}`);
        }
      })
      .catch((error) => setModalTitle(`${error.name}: ${error.message}`));
  };

  const visiblePost =
    visiblePostName === "all"
      ? postItems
      : [postItems.find((item) => item.postNum === visiblePostName)];

  console.log(postItems);

  return (
    <>
      <Grid container spacing={{ xs: 2, md: 5 }} pt={"20px"} pl={1}>
        {visiblePost.map(
          (
            {
              idWash,
              postKey,
              postNum,
              currCoinsBal,
              currMonBal,
              currTermBal,
              currentProg,
              typePost,
              wifiSignal,
              currentMoney,
              warranty,
              lastUpdateTime,
            },
            index
          ) => {
            return (
              <Grid key={postKey} item>
                <Card
                  raised
                  sx={{
                    borderRadius: 3,
                    width: "350px",
                    background: dayjs().tz("Europe/Moscow").isAfter(
                      dayjs(lastUpdateTime).add(120, "second")
                    )
                      ? "rgba(46, 46, 46, 0.3)"
                      : theme.palette.background.opacityCard,
                  }}
                >
                  <CardContent>
                    <Grid container flexDirection={"row"}>
                      <Grid item container spacing={1} alignItems={"center"}>
                        <Grid item>
                          <Typography
                            variant="h5"
                            color={"text.secondary"}
                            textTransform={"uppercase"}
                            fontWeight={"bold"}
                          >
                            {postNum}
                          </Typography>
                        </Grid>
                        <Grid item flexGrow={1} mt={"-5px"}>
                          <EditPost postItems={postItems} />
                        </Grid>
                        <Grid item height={"100%"}>
                          <SvgIcon
                            color="secondary"
                            fontSize="large"
                            component={
                              dayjs().tz("Europe/Moscow").isAfter(
                                dayjs(lastUpdateTime).add(120, "second")
                              )
                                ? Wifi0
                                : displayWifiIcon(wifiSignal)
                            }
                            inheritViewBox
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            color={"text.secondary"}
                            fontSize={"12px"}
                            fontWeight={"bold"}
                          >
                            {wifiSignal} дБм
                          </Typography>
                        </Grid>
                        <Grid item mt={"-5px"}>
                          <IconButton
                            color="secondary"
                            aria-describedby={`popover ${index}`}
                            onClick={(event) => handleClick(event, index)}
                            children={<InfoOutlinedIcon fontSize="large" />}
                          ></IconButton>
                        </Grid>
                        <Popover
                          id={`popover ${index}`}
                          open={open[index] ?? false}
                          anchorEl={anchorElArr[index]}
                          onClose={() => handleClose(index)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            Тип поста: {typePost}
                          </Typography>
                          <Typography sx={{ p: 2 }}>
                            Ключ поста: {postKey}
                          </Typography>
                          <Typography sx={{ p: 2 }}>
                            Гарантия до: {warranty}
                          </Typography>
                          <Typography sx={{ p: 2 }}>
                            Обновление: {lastUpdateTime}
                          </Typography>
                        </Popover>
                      </Grid>
                      <Grid
                        item
                        container
                        flexDirection={"row"}
                        mt={1}
                        width={"320px"}
                        marginLeft={"-16px"}
                        spacing={1}
                        sx={{
                          borderRadius: "0 20px 20px 0",
                          color: "black",
                          backgroundColor:
                            theme.palette.background.opacitySecondary,
                          boxShadow: "inset -8px 1px 18px 0px rgba(0,0,0,0.2)",
                        }}
                        border={"5px solid secondary"}
                        // bgcolor={'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'}
                        pl={2}
                        pr={3}
                        pt={2}
                      >
                        <Grid item xs={12}>
                          <Typography
                            textAlign={"start"}
                            gutterBottom
                            fontWeight={"bold"}
                            color={"text.secondary"}
                            textTransform={"uppercase"}
                            fontSize={24}
                          >
                            {checkProgramm(+currentProg)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            textAlign={"start"}
                            gutterBottom
                            fontWeight={"bold"}
                            color={"text.secondary"}
                            textTransform={"uppercase"}
                            fontSize={20}
                          >
                            {" "}
                            Баланс: {currentMoney}₽
                          </Typography>
                        </Grid>
                        <Grid item container xs={12} justifyContent={"end"}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              borderRadius: "15px",
                              width: "150px",
                              boxShadow: "6px 4px 18px -10px rgba(0,0,0,0.75)",
                            }}
                            onClick={() =>
                              makeIncassation({
                                cash: +currMonBal,
                                idWash: +idWash,
                                numPost: +postNum,
                              })
                            }
                          >
                            <Typography
                              textAlign={"end"}
                              gutterBottom
                              color={"text.secondary"}
                              textTransform={"uppercase"}
                              fontSize={12}
                            >
                              Купюры: {+currMonBal}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item container xs={12} justifyContent={"end"}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              borderRadius: "15px",
                              width: "150px",
                              boxShadow: "6px 4px 18px -10px rgba(0,0,0,0.75)",
                            }}
                            onClick={() =>
                              makeIncassation({
                                coins: +currCoinsBal,
                                idWash: +idWash,
                                numPost: +postNum,
                              })
                            }
                          >
                            <Typography
                              textAlign={"end"}
                              gutterBottom
                              color={"text.secondary"}
                              textTransform={"uppercase"}
                              fontSize={12}
                            >
                              Монеты: {+currCoinsBal}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent={"end"}
                          marginBottom={2}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              borderRadius: "15px",
                              width: "150px",
                              boxShadow: "6px 4px 18px -10px rgba(0,0,0,0.75)",
                            }}
                            onClick={() =>
                              makeIncassation({
                                terminal: +currTermBal,
                                idWash: +idWash,
                                numPost: +postNum,
                              })
                            }
                          >
                            <Typography
                              textAlign={"end"}
                              gutterBottom
                              color={"text.secondary"}
                              textTransform={"uppercase"}
                              fontSize={12}
                            >
                              Терминал: {+currTermBal}
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent={"end"}
                          marginBottom={2}
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            sx={{
                              borderRadius: "15px",
                              width: "150px",
                              boxShadow: "6px 4px 18px -10px rgba(0,0,0,0.75)",
                            }}
                            onClick={() =>
                              makeIncassation({
                                cash: +currMonBal,
                                terminal: +currTermBal,
                                coins: +currCoinsBal,
                                idWash: +idWash,
                                numPost: +postNum,
                              })
                            }
                          >
                            <Typography
                              textAlign={"end"}
                              gutterBottom
                              color={"text.secondary"}
                              textTransform={"uppercase"}
                              fontSize={12}
                            >
                              Всего:{" "}
                              {+currTermBal + +currCoinsBal + +currMonBal}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} alignContent={"end"}>
                        <Grid
                          item
                          container
                          xs={6}
                          justifyContent={"end"}
                          mt={2}
                        >
                          <PostReplenishment />
                        </Grid>
                        {/* <Grid mt={4} item xs={6}><Typography textAlign={'end'} fontSize={10} color={'text.secondary'} ></Typography></Grid>  */}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          }
        )}
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen ? modalOpen : false}
        onClose={handleCloseModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              textAlign={"center"}
            >
              {modalTitle}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Пост: {incasationsData.numPost}
            </Typography>
            <Typography
              display={incasationsData.cash == "0" ? "none" : "block"}
              id="transition-modal-description"
              sx={{ mt: 1 }}
            >
              Купюры: {incasationsData.cash}
            </Typography>
            <Typography
              display={incasationsData.terminal == "0" ? "none" : "block"}
              id="transition-modal-description"
              sx={{ mt: 1 }}
            >
              Терминал: {incasationsData.terminal}
            </Typography>
            <Typography
              display={incasationsData.coins == "0" ? "none" : "block"}
              id="transition-modal-description"
              sx={{ mt: 1 }}
            >
              Монеты: {incasationsData.coins}
            </Typography>
            {!isAdded ? (
              <Grid container justifyContent={"space-around"} mt={2}>
                <Button color="error" onClick={handleCloseModal}>
                  Отмена
                </Button>
                <Button color="success" onClick={() => sendData()}>
                  Инкасcация
                </Button>
              </Grid>
            ) : null}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PostInfo;
