import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

export default function Documentation() {
  return (
    <Stack spacing={1}>
      <Typography textAlign={'center'} fontSize={25} fontWeight={'bold'}>Страница находится в разработке</Typography>  
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation={'wave'} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation={'wave'} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation={'wave'} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Typography textAlign={'end'} pr={2} color={'text.secondary'} fontSize={12}>v.1.006 21.03.25</Typography>
    </Stack>
  );
}