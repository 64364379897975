import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  redirect,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
} from "@mui/material/";
import { json } from "react-router-dom";

const LoginPage = (props) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isLogined, setIsLogined] = useState(false);
  const [errorMessage, setErrorMesage] = useState("");

  const handleLoginChange = (event) => {
    setLogin(() => event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(() => event.target.value);
  };

  const handleLogin = (e) => {
    // let jwt;
    e.preventDefault();
    fetch("https://atod.online/auth.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: login, password: password }),
    })
      .then((response) => {
        if (response.ok) {
          setErrorMesage("");
          return response.text();
        } else {
          setErrorMesage(`Ошибка авторизации`);
          throw new Error(response.statusText);
        }
      })
      .then((token) => {
        localStorage.setItem("jwt", token);
        setIsLogined(true);
        props.makeAuth(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {isLogined && localStorage.getItem("jwt") ? <Navigate to={"/"} /> : null}
      <FormControl onSubmit={handleLogin}>
        <Grid
          container
          position={"fixed"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={"-15px"}
          ml={"-18px"}
          minHeight={"102vh"}
          minWidth={"105%"}
          bgcolor={"background.default"}
          sx={{ flexGrow: "1" }}
        >
          <Grid maxWidth={"340px"}>
            <Paper
              elevation={3}
              style={{ padding: "20px" }}
              sx={{ borderRadius: "20px" }}
            >
              <Typography variant="h5" gutterBottom>
                Авторизация
              </Typography>
              {/* <Typography variant="subtitle1" gutterBottom>
                Не зарегистрированы?{" "}
                <Typography
                  component={Link}
                  to={"/signup"}
                  variant="button"
                  color={"secondary"}
                >
                  Регистрация
                </Typography>
              </Typography> */}
              <form>
                <TextField
                  fullWidth
                  label="Логин"
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{ disableAnimation: true, shrink: true }}
                  // multiline
                  value={login}
                  onChange={(event) => handleLoginChange(event)}
                />
                <TextField
                  fullWidth
                  label="Пароль"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  InputLabelProps={{ disableAnimation: true, shrink: true }}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <Typography
                  minWidth={"10px"}
                  textAlign={"center"}
                  color={"red"}
                >
                  {errorMessage}
                </Typography>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={handleLogin}
                  style={{ marginTop: "10px" }}
                >
                  Войти
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

export default LoginPage;
