import IncasationJournal from "../../components/IncasationJournal";
import { Grid, Card, Box, Stack, Divider, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Typography } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { Navigate } from "react-router-dom";

import DataBaseService from "../../services/dataBaseService";

const dataBaseService = new DataBaseService();

const Incasations = () => {
  const [startDay, setStartDay] = useState(() => dayjs().startOf("month"));
  const [endDay, setEndDay] = useState(() => dayjs());
  const [isAuth, setIsAuth] = useState(true);
  const [incasationsData, setIncasationsData] = useState([]);
  const [visibleIncasationsData, setVisibleIncasationsData] = useState("");
  const [visibleWashName, setVisibleWashName] = useState(
    sessionStorage.getItem("washName")
      ? `${JSON.parse(sessionStorage.getItem("washName"))[0].street} ${
          JSON.parse(sessionStorage.getItem("washName"))[0].building
        }`
      : "all"
  );
  const [washList, setWashList] = useState([]);
  const [currentPost, setCurrentPost] = useState();
  const theme = useTheme();

  useEffect(() => {
    setVisibleIncasationsData(
      visibleWashName === "all"
        ? incasationsData
        : incasationsData.filter(
            (item) => `${item.street} ${item.building}` == visibleWashName
          )
    );
  }, [visibleWashName, incasationsData]);
  console.log(visibleIncasationsData);

  useEffect(() => {
    incasationsData &&
      setWashList(
        Array.from(
          new Set(
            incasationsData.map((item) => `${item.street} ${item.building}`)
          )
        )
      );
  }, [incasationsData]);

  const getIncasationsData = () => {
    fetch(
      `https://atod.online/getCollectionLog.php?wht=jr&since=${startDay.format(
        "YYYY-MM-DD"
      )}&until=${endDay.format("YYYY-MM-DD")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log(response);
          return response.json();
        } else if (response.status === 401) {
          localStorage.removeItem("jwt");
          setIsAuth(false);
          console.log(response);
          throw new Error(`Ошибка ${response.status} : ${response.statusText}`);
        } else {
          console.log(response);
          throw new Error(`Ошибка ${response.status} : ${response.statusText}`);
        }
      })
      .then((data) => {
        setIsAuth(true);
        setIncasationsData(data);
      })
      .catch((error) => console.log(error));
  };
  console.log(incasationsData);

  useEffect(() => {
    getIncasationsData();
  }, [startDay, endDay]);

  const handleChange = (event) => {
    setVisibleWashName(event.target.value);
  };

  return (
    <>
      {!isAuth ? <Navigate to={"/login"} /> : null}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <Grid container columnSpacing={2} spacing={1} justifyContent={"start"}>
          <Grid item ml={1}>
            <DatePicker
              label="Начало периода"
              value={startDay}
              onChange={(newValue) => setStartDay(newValue)}
            />
          </Grid>
          <Grid item ml={1}>
            <DatePicker
              label="Конец периода"
              value={endDay}
              onChange={(newValue) => setEndDay(newValue)}
            />
          </Grid>
          <Grid item mt={-1}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="normal">
              <InputLabel id="demo-select-small-label" shrink>
                Мойка
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={visibleWashName}
                label="Мойка"
                onChange={handleChange}
              >
                <MenuItem value="all">Все</MenuItem>
                {washList.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item alignSelf={"center"}>
            <Typography>
              {startDay > endDay ? "не правильный период" : null}
            </Typography>
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Stack
        direction="row"
        flexWrap={"wrap"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          justifyContent: "space-around",
          mb: 2,
          mt: 2,
        }}
      >
        <Card
          sx={{
            width: 340,
            height: 220,
            m: 2,
            background: theme.palette.background.opacityCard,
            borderRadius: 4,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Общая инкасируемая сумма по всем постам
            </Typography>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                justifySelf={"center"}
              >
                Все посты
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData.reduce((acc, item) => {
                      return acc + +item.cash + +item.coins + +item.terminal;
                    }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography gutterBottom variant="body1" component="div">
                Купюры
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData.reduce((acc, item) => {
                      return acc + +item.cash;
                    }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography gutterBottom variant="body1" component="div">
                Монеты
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData.reduce((acc, item) => {
                      return acc + +item.coins;
                    }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography gutterBottom variant="body1" component="div">
                Терминал
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData.reduce((acc, item) => {
                      return acc + +item.terminal;
                    }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
          </Box>
        </Card>
        <Card
          sx={{
            width: 340,
            height: 220,
            m: 2,
            background: theme.palette.background.opacityCard,
            borderRadius: 4,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Общая инкасируемая сумма по выбранному посту
            </Typography>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "end" }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                justifySelf={"center"}
              >
                Пост №
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  id="demo-select-small"
                  value={currentPost ? currentPost : ""}
                  onChange={(event) => setCurrentPost(event.target.value)}
                >
                  {visibleIncasationsData
                    ? Array.from(
                        new Set(
                          visibleIncasationsData.map(
                            (item) => `${item.postNum}`
                          )
                        )
                      ).map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
              </FormControl>
              <Typography gutterBottom variant="h6" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData
                      .filter((item) => item.postNum === currentPost)
                      .reduce((acc, item) => {
                        return acc + +item.cash + +item.coins + +item.terminal;
                      }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography gutterBottom variant="body1" component="div">
                Купюры
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData
                      .filter((item) => item.postNum === currentPost)
                      .reduce((acc, item) => {
                        return acc + +item.cash;
                      }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography gutterBottom variant="body1" component="div">
                Монеты
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData
                      .filter((item) => item.postNum === currentPost)
                      .reduce((acc, item) => {
                        return acc + +item.coins;
                      }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography gutterBottom variant="body1" component="div">
                Терминал
              </Typography>
              <Typography gutterBottom variant="body2" component="div">
                {visibleIncasationsData
                  ? visibleIncasationsData
                      .filter((item) => item.postNum === currentPost)
                      .reduce((acc, item) => {
                        return acc + +item.terminal;
                      }, 0)
                  : 0}{" "}
                ₽
              </Typography>
            </Stack>
          </Box>
        </Card>
      </Stack>
      {visibleIncasationsData ? (
        <IncasationJournal data={visibleIncasationsData} />
      ) : null}
    </>
  );
};

export default Incasations;
