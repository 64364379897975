import React, { useEffect } from 'react';
import Header from './Header'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { grey, teal, cyan } from '@mui/material/colors';

import TodayStatistic from './TodayStatistic';
import PostInfo from './PostsInfo';
import MainPage from './pages/MainPage';
import Statistics from './pages/Statistics';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import Incasations from './pages/Incasations';
import Documentation from './pages/Documentation';
import Control from './pages/Control';
import ClientsCards from './pages/ClientsCards';
import CardReplenishment from './pages/CardReplenishment';
import RequireAuth from '../services/UseAuth'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import LoginPage from './pages/LoginPage';

import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import SignUp from './pages/SignUp';

// const DashboardAuthRequire = () => {
//   <RequireAuth>
//     <Header/>
//   </RequireAuth>
// }

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: grey,
          divider: grey[700],
          
        }
      : {
          // palette values for dark mode
          primary: grey,
          secondary: teal,
          divider: grey[800],
          background: {
            default: grey[900],
            paper: grey[900],
          },
          action: cyan,
          text: {
            primary: '#fff',
          },
        }),
  },
});

function App() {

  const [mode, setMode] = React.useState('dark');
  const [isLogined, setIsLogined] = React.useState(true);

  useEffect(() => {
    setIsLogined( localStorage.getItem('jwt') ? true : false)
    console.log(document.cookie)
  }, [])

  const makeAuth = (isLogined) => {
    setIsLogined(isLogined)
  }

  // const authCheck = () => {
  //   if (!isLogined) {
  //     return <Navigate to={'/login'}/>
  //   }
  // }

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  
  return (
    <Router basename='/'>
      <ThemeProvider theme={theme}>
        {/* {authCheck()} */}
        <Routes>
          <Route path='/' element={<RequireAuth auth={isLogined}><Header/></RequireAuth>}> 
            <Route path='/profile' element={<Profile/>}/>
            <Route path='/statistics' element={<Statistics/>}/>
            <Route path='/settings' element={<Settings/>}/>
            <Route path='/incasations' element={<Incasations/>}/>
            <Route path='/documentation' element={<Documentation/>}/>
            <Route path='/control' element={<Control/>}/>
            <Route path='/clients' element={<ClientsCards/>}/>
            <Route path='/replenishment' element={<CardReplenishment/>}/>
          </Route>
          <Route path='/login' element={<LoginPage makeAuth={makeAuth}/>}/>
          <Route path='/signup' element={<SignUp/>}/>
        </Routes>
      </ThemeProvider>
    </Router>  
  );
}

export default App;
