import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, redirect, Navigate, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid
} from '@mui/material/'
import { json } from 'react-router-dom';


const SignUp = (props) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('')
  const [lastname, setLastname] = useState('')
  const [mobile, setMobile] = useState('')
  const [errorMessage, setErrorMesage] = useState('')

  const handleFieldChange = (event, f) => {
    f(() => event.target.value);
  };


  const handleLogin = () => {
    // let jwt;
    fetch('https://atod.online/auth.php', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({name: login, password: password})
    })
    .then(response => { 
      if (response.ok) {
        setErrorMesage('');
        return response.text()
      } else {
        setErrorMesage(`Ошибка регистрации`)
        throw new Error(response.statusText)
      }
    })
    .then(token => {
      localStorage.setItem('jwt', token);
      props.makeAuth(true);

      })
    .catch(error => console.log(error))
  };

  return (
    <>
    <Grid 
        container
        position={'fixed'}
        alignItems={'center'}
        justifyContent={'center'}
        mt={'-15px'}
        ml={'-18px'}
        minHeight={'102vh'}
        minWidth={'105%'}
        bgcolor={'background.default'}
        sx={{flexGrow: '1'}}>
       <Grid maxWidth={'340px'}>
            <Paper elevation={3} style={{ padding: '20px' }} sx={{borderRadius: '20px'}}>
                <Typography variant="h5" gutterBottom>
                Регистрация
                </Typography>
                <Typography variant='subtitle1' gutterBottom>
                Уже зарегистрированы? <Typography component={Link} to={'/login'} variant='button' color={'secondary'}>Войти</Typography>
                </Typography>
                <form>
                <TextField
                    fullWidth
                    label="Логин"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{disableAnimation: true, shrink:true}}
                    // multiline
                    value={login}
                    onChange={(event) => handleFieldChange(event, setLogin)}
                />
                <TextField
                    fullWidth
                    label="Имя"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{disableAnimation: true, shrink:true}}
                    // multiline
                    value={name}
                    onChange={(event) => handleFieldChange(event, setName)}
                />
                <TextField
                    fullWidth
                    label="Фамилия"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{disableAnimation: true, shrink:true}}
                    // multiline
                    value={surname}
                    onChange={(event) => handleFieldChange(event, setSurname)}
                />
                <TextField
                    fullWidth
                    label="Отчество"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{disableAnimation: true, shrink:true}}
                    // multiline
                    value={lastname}
                    onChange={(event) => handleFieldChange(event, setLastname)}
                />
                <TextField
                    fullWidth
                    label="Номер телефона"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{disableAnimation: true, shrink:true}}
                    // multiline
                    value={mobile}
                    onChange={(event) => handleFieldChange(event, setMobile)}
                />
                <TextField
                    fullWidth
                    label="Пароль"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{disableAnimation: true, shrink:true}}
                    value={password}
                    onChange={(event) => handleFieldChange(event, setPassword)}
                />
                <TextField
                    fullWidth
                    label="Повторите пароль"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{disableAnimation: true, shrink:true}}
                    value={confirmPassword}
                    onChange={(event) => handleFieldChange(event, setConfirmPassword)}
                />
                <Typography minWidth={'10px'} textAlign={'center'} color={'red'}>{errorMessage}</Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleLogin}
                    style={{ marginTop: '10px' }}
                >
                    Зарегистрироваться
                </Button>
                </form>
            </Paper>
      </Grid> 
    </Grid>
    </>
  );
}

export default SignUp;